<template>
  <form action="" class="register__form" @submit.prevent="registerUser()">
    <!-- Form heading -->
    <h2 class="w-full text-center text-base font-medium pb-3 mb-4 border-b border-black">Créer votre compte</h2>
    <!-- Email input -->
    <div class="register__form-group">
      <div 
        :class="{'register__form-item':true, 'register__form-item--error':v$.email.$errors.length}">
        <span class="register__form-item-notice">Requis *</span>
        <input 
          class="register__form-item-input" 
          type="text" 
          v-model="user.email" placeholder="Email" 
          @blur="v$.email.$touch"
          autocomplete
        />
      </div>        
      <div class="register__form-item-error" v-for="(error, index) of v$.email.$errors" :key="index">
        {{ error.$message }}
      </div>
      <div class="register__form-item-error" v-show="serverError.length && !v$.email.$errors.length">
          {{serverError}}
      </div>
    </div>
    <!-- Password input -->
    <div class="register__form-group">
      <div 
        :class="{'register__form-item':true, 'register__form-item--error':v$.password.$errors.length}">
        <span class="register__form-item-notice">Requis *</span>
        <input 
          class="register__form-item-input" 
          type="password" 
          v-model="user.password" 
          placeholder="Mot de passe" 
          @blur="v$.password.$touch"
        />
      </div>        
      <div class="register__form-item-error" v-for="(error, index) of v$.password.$errors" :key="index">
        {{ error.$message }}
      </div>
    </div>
    <!-- first/last name inputs -->
    <div class="flex gap-4">
      <div class="register__form-group">
        <div :class="{'register__form-item':true, 'register__form-item--error':v$.firstname.$errors.length}">
          <span class="register__form-item-notice">Requis *</span>
          <input 
            class="register__form-item-input" 
            type="text" 
            placeholder="Prénom"
            v-model="user.firstname" 
            @blur="v$.firstname.$touch" 
            autocomplete
            />
        </div>        
        <div class="register__form-item-error" v-for="(error, index) of v$.firstname.$errors" :key="index">
          {{ error.$message }}
        </div>
      </div>
      <div class="register__form-group">
        <div :class="{'register__form-item':true, 'register__form-item--error':v$.lastname.$errors.length}">
          <span class="register__form-item-notice">Requis *</span>
          <input 
            class="register__form-item-input" 
            type="text" 
            placeholder="Nom"
            v-model="user.lastname" 
            @blur="v$.lastname.$touch"
            />
        </div>        
        <div class="register__form-item-error" v-for="(error, index) of v$.lastname.$errors" :key="index">
          {{ error.$message }}
        </div>
      </div>
    </div> 
    <!-- Birthday input -->
    <div class="register__form-group mb-1">
      <div :class="{'register__form-item':true, 'register__form-item--error':v$.birthday.$errors.length}">
        <span class="register__form-item-notice">Requis *</span>
        <input 
          class="register__form-item-input" 
          type="text"
          placeholder="YYYY-MM-DD" 
          v-model="user.birthday"
          @blur="v$.birthday.$touch" 
          />
      </div>        
      <div class="register__form-item-error" v-for="(error, index) of v$.birthday.$errors" :key="index">
        {{ error.$message }}
      </div>
    </div>
    <!-- Profession input -->
    <div class="register__form-group mb-1">
      <div :class="{'register__form-item':true, 'register__form-item--error':v$.job.$errors.length}">
        <select id="job" name="job" placeholder="Profession" autocomplete="job-name" v-model="v$.job.$model" class="register__form-item-select">
          <option
            v-for="(item, index) in state.communities.communities"
            :key="index"
            :value="item.name"
          >
              {{ item.name }}
          </option>
        </select>
      </div>        
      <div class="register__form-item-error" v-for="(error, index) of v$.job.$errors" :key="index">
        {{ error.$message }}
      </div>
    </div>
    <!-- Organisme input -->
    <div class="register__form-group">
      <div :class="{'register__form-item':true, 'register__form-item--error':v$.organization.$errors.length}">
        <input class="register__form-item-input" type="text" v-model="v$.organization.$model" placeholder="Organisme" />
      </div>        
      <div class="register__form-item-error" v-for="(error, index) of v$.organization.$errors" :key="index">
        {{ error.$message }}
      </div>
    </div>
    <!-- Avatar input -->
    <!-- <div class="register__form-group">
      <div :class="{'register__form-item':true, 'register__form-item--error':v$.avatar.$errors.length}">
        <label for="avatar" class="register__form-item-input register__form-item-input--avatar">
          <input 
            id="avatar"
            type="file" 
            accept="image/*"
            @change="updateAvatar"
            hidden>
          <atom-icon v-if="!avatar?.length" class="register__form-item-icon" icon="user-smile"></atom-icon>
          <img v-else class="register__form-item-avatar" :src="avatar" alt="Avatar">
          <span class="flex-1">Choose avatar</span>
        </label>
      </div>        
      <div class="register__form-item-error" v-for="(error, index) of v$.avatar.$errors" :key="index">
        {{ error.$message }}
      </div>
    </div> -->
    <atom-button variant="primary md full">S'enregistrer</atom-button>
    <atom-link 
      variant="black" 
      class="mt-5 text-center w-full"
      :to="{name:'Login'}">
      Si vous avez déjà un compte, veuillez vous connecter
    </atom-link>
  </form>
  <div class="register__quote">
    <h1 class="register__quote-content">"Un manque de transparence se traduit par la méfiance et un profond sentiment d'insécurité."</h1>
    <span class="register__quote-author">- Dalai Lama, The Telegraph, 13 Mai 2012.</span>
  </div>
</template>

<script setup>
  import AtomInput from '@/components/atoms/AtomInput.vue';
  import AtomButton from '@/components/atoms/AtomButton.vue';
  import AtomLink from '@/components/atoms/AtomLink.vue';
  import {ref, computed, onBeforeMount} from "vue";
  import { useVuelidate } from '@vuelidate/core';
  import { email, required, helpers } from '@vuelidate/validators';
  import {useRouter} from 'vue-router';
  import {useStore} from 'vuex';
  import {filesMutationTypes} from "@/store/types/mutations";
  import { usersMutationTypes } from "@/store/types/mutations";

  // Use router
  const router = useRouter();
  // Use store 
  const {commit, dispatch, state, getters} = useStore();
  // User model
  const user = ref({
    firstname: '',
    lastname: '',
    email:'',
    password:'',
    birthday:'',
    job:'',
    organization:'',
  });


onBeforeMount( async () => {
    try {
      await dispatch('communities/getAll');
      v$.value.job = state.communities.communities[0].name;
    } catch (err) {
      const {message} = err.response.data;
    }
});

  // Email atomicity error
  const serverError = ref('');
  // Validation rules
  const date = helpers.regex(/^[12][0-9]{3}-[01][1-9]-[0-3][0-9]$/);
  const password = helpers.regex(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/);
  const rules = {
    firstname: {required},
    lastname: {required},
    email: {required, email},
    password: {
      required, 
      password: helpers.withMessage("Au minimum huit caractères, avec au moins une lettre et un chiffre.", password)
    },
    birthday: {
      required, 
      date:helpers.withMessage("La valeur n'est pas une date de naissance valide.", date)
    },
    job: {},
    organization: {},
  }
  const v$ = useVuelidate(rules, user);
  // Register user function
  const registerUser = async () => {
    const isFormValid = !v$.value.$invalid;
    if(!isFormValid){
      v$.value.$touch();
    }
    // Form is valid
    else{
      try {
        const {exist} = await dispatch('auth/doesEmailExists', user.value.email);
        // If user exists
        if(exist){
          serverError.value = "Email already exists";
        }
        else{
          commit(`users/${usersMutationTypes.SET_NEW_USER}`, user.value);
          router.push({
            name:'AdmissionTest'
          });
        }
      }
      catch(err){
        console.log(err);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .register{
    &__form{
      @apply w-full max-w-[500px] bg-white
      py-5 px-2 lg:px-[80px];
      &-group{
        @apply relative flex;
      }
      &-item {
        @apply mb-5 w-full;
        &--error{
          .register__form-item{
            &-input{
              @apply border-red-600 border-2 
              focus:ring-0;
            }
          }
        }
        &-notice {
          @apply text-[10px] text-[#C4C4C4] inline-block mb-[3px];
        }
        &-input {
          @apply relative w-full py-2 px-4 border border-gray-100 placeholder-gray-base rounded-md;
          &--avatar{
            @apply flex items-center gap-1 text-gray-base max-h-9 cursor-pointer;
          }
        }
        &-select {
          @apply mt-1 block w-full py-2 px-3 border border-gray-100 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm;
          option{
            @apply py-1 inline-block;
          }
        }
        &-error{
          @apply absolute bottom-[3px] right-0 text-xs text-red-600;
        }
        &-avatar{
          @apply h-7 w-7 object-cover rounded-full shadow-md;
        }
      }
      &-error {
        @apply text-red-600 mb-1;
      }
    }
    &__quote{
      @apply hidden lg:block p-5 bg-black/40 max-w-[600px] w-full;
      &-content{
        @apply text-white font-medium 
        text-[30px] xl:text-[36px];
      }
      &-author{
        @apply text-white font-medium text-sm;
      }
    }
  }
</style>